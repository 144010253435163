<template>
<!-- <p>Gráfica Presidente Suplente</p> -->
<div class="componente-grafica"> 
  <h4>Candidatura a {{titulo}}</h4>
  <column-chart 
  :data="[['Hombres',hombres],['Mujeres',mujeres]]"
  :colors="['#43e287', '#e28743']"
  ></column-chart>
</div>
  
</template>

<script>
export default {
  props:{
    hombres: { type: Number, default:0},
    mujeres: { type: Number, default:0},
    titulo: { type: String }
  },

}
</script>

<style>
  
</style>