<template>
  <div class="home-contenedor">

    <div class="menu-paridad">
      <router-link to="/partidos-paridad">Análisis Partidos Políticos</router-link>
    </div>


    <!-- Presidencia -->
    <div class="mapa-grafica">
      
      <div class="mapa">
          <h2 class="titulo">Presidencia</h2>
          <MapaPresidencia name="mapa"/>
      </div>
      <div class="mapa">
          <h2 class="titulo">Sindicatura</h2>
          <MapaSindicatura name="mapaSindicatura"/>
      </div>
      <div class="mapa">
          <h2 class="titulo">Regidurías</h2>
          <MapaRegiduria name="mapaRegiduria"/>
      </div>


    </div>

    
  </div>
  <Footer />
</template>

<script>

import MapaPresidencia from '../components/MapaPresidencia.vue'
import MapaSindicatura from '../components/MapaSindicatura.vue'
import MapaRegiduria from '../components/MapaRegidurias.vue'

import Footer from '../components/Footer.vue'

import Grafica from '../components/Grafica.vue'

export default {
  name: 'Home',
  components:{
    MapaPresidencia,
    MapaSindicatura,
    MapaRegiduria,
    Grafica,
    Footer
    
  }
}
</script>

<style scoped>

  .menu-paridad{
    width:70%;
    margin:5px auto;
    text-align:right;
  }
  .home-contenedor{
    width:90%;
    height:100%;
    margin:10px auto;
    background-color: #f2f2f2;
    display:flex; flex-wrap:wrap; align-items:center; justify-content:center;
  }
  .municipios{
    width:30%;
    height:30px;
    /* border:1px solid black; */
    display:flex; align-items:center; justify-content:center;
  }
  .municipios p{
    cursor:pointer;
  }
  .mapa-grafica{
    width:80%;
  }
  .mapa{
    width:100%;
  }
  .grafica{
    width:50%;
    height:auto;
    display:flex; align-items:center; justify-content:center;
  }
</style>
